import moment from 'moment'

import { escapeQuotes } from 'helpers/csv'
import { avgKeysCurrency, sumKeys } from 'helpers/objects'
import {
  BRANDS_MAX_DROPDOWN,
  NEGATIVE_TREE_MAP_GRAPH_COLORS,
  POSITIVE_TREE_MAP_GRAPH_COLORS,
  TREE_MAP_GRAPH_COLORS,
  ZERO_TREND_TREE_MAP_GRAPH_COLOR
} from 'constants/market_insights/share_and_trends'

import { PRODUCT_TRENDS_CSV_EXPORT_COLUMNS } from 'constants/market_insights/product_trends'

import { LEGACY_COMPARISON_TYPES } from 'ui_elements/CalendarSelect/CalendarSelect'
import { safeSegmentCall, sendSegmentTrackEvent } from 'services/segment'
import { SHARE_AND_TRENDS_FILTERED } from 'constants/activation_points/share_and_trends'
import { MARKETPLACES } from 'constants/countries'
import { COMPARISON_TYPES } from 'constants/calendar'
import { getCurrencyCode, parseCurrency } from 'helpers/currency'
import { humanizeLongNumberWithLocale } from 'helpers/formatters'

export const toggleSelectedBrand = (brand, selectedBrands) => {
  const selected = { ...selectedBrands }
  const id = brand.name

  if (id in selected) {
    delete selected[id]
  } else if (Object.keys(selected).length < BRANDS_MAX_DROPDOWN) {
    selected[id] = brand
  }

  return selected
}

export const getGroupingType = trendsType => {
  if (!trendsType) return null

  const fieldMapping = {
    asins: 'max',
    avgReviews: 'avg',
    avgPrice: 'avgCurrency',
    keywordRank: 'avg'
  }

  return fieldMapping[trendsType] || 'sum'
}

export const formatDateCompareRangeForCsv = (
  startDate,
  endDate,
  differenceInDays
) =>
  `"Date Range: ${startDate.format('MMMM D, YYYY')} - ${endDate.format(
    'MMMM D, YYYY'
  )}  (Comparing to: ${startDate
    .subtract(differenceInDays, 'days')
    .format('MMMM D, YYYY')} - ${endDate
    .subtract(differenceInDays, 'days')
    .format('MMMM D, YYYY')})"`

export const formatProductTrendForExport = (
  trends,
  startDate = moment().subtract(2, 'years'),
  endDate = moment(),
  t
) => {
  const trendColumnData = PRODUCT_TRENDS_CSV_EXPORT_COLUMNS(t)

  const differenceInDays = endDate.diff(startDate, 'days')

  let csvContent = `${formatDateCompareRangeForCsv(
    startDate,
    endDate,
    differenceInDays
  )}\n`

  csvContent += `${trendColumnData.columnName.join(',')}\n`

  // sort so the item with highest share is first.
  const productTrendsListSorted = trends.sort(
    (a, b) => parseFloat(b.share) - parseFloat(a.share)
  )

  csvContent += productTrendsListSorted
    .map((productTrend, index) => {
      return (
        `${index + 1},` +
        `"${escapeQuotes(productTrend.name)}",` +
        `"${escapeQuotes(productTrend.brand)}",` +
        `${productTrend.asin},` +
        `${productTrend.revenue},` +
        `${parseInt(productTrend.shareTrend, 10)}%`
      )
    })
    .join('\n')

  return csvContent
}

export const calculateTreeMapColor = trend => {
  const roundedTrend = Math.round(trend)
  const isPositive = roundedTrend > 0

  if (roundedTrend === 0) {
    return ZERO_TREND_TREE_MAP_GRAPH_COLOR
  }

  if (roundedTrend >= 100 || roundedTrend <= -100) {
    return isPositive
      ? TREE_MAP_GRAPH_COLORS[TREE_MAP_GRAPH_COLORS.length - 1]
      : TREE_MAP_GRAPH_COLORS[0]
  }

  const ratio = Math.abs(trend) / 100
  const colors = isPositive
    ? POSITIVE_TREE_MAP_GRAPH_COLORS
    : NEGATIVE_TREE_MAP_GRAPH_COLORS
  const index = Math.floor(colors.length * ratio)

  return colors[index]
}

export const aggregateSegmentData = (segmentTrends, startDate, endDate) => {
  if (!Array.isArray(segmentTrends)) {
    return null
  }

  const dateFiltered = segmentTrends.filter(item =>
    moment.utc(item.date).isBetween(startDate, endDate, 'day', '[]')
  )

  const keysToSum = ['revenue', 'unitSales']
  const summed = sumKeys(dateFiltered, keysToSum)

  const keysToAvg = ['avgReviews']
  const averaged = avgKeysCurrency(dateFiltered, keysToAvg)
  // avgPrice should exclude 0s
  const avgPrices = dateFiltered.reduce((a, e) => {
    if (e.avgPrice > 0) a.push(e.avgPrice)
    return a
  }, [])
  // no divide by 0 should happen if avgPrice.length === 0
  averaged.avgPrice = (
    avgPrices.reduce((a, e) => a + e, 0) / avgPrices.length
  ).toFixed(2)

  const [last] = dateFiltered.slice(-1)
  return {
    total_brands: last?.brands,
    total_revenue: summed.revenue,
    monthly_unit_sales: summed.unitSales,
    average_reviews: averaged.avgReviews,
    total_asins: last?.asins,
    average_price: averaged.avgPrice
  }
}

export const computePreviousPeriodRange = (
  startDate,
  endDate,
  comparisonType,
  dateRangeType
) => {
  if (
    comparisonType === COMPARISON_TYPES.previousYear ||
    comparisonType === LEGACY_COMPARISON_TYPES.previousYear
  ) {
    const previousStartDate = startDate.clone().subtract(1, 'years')
    const previousEndDate = endDate.clone().subtract(1, 'years')
    return { previousStartDate, previousEndDate }
  }

  switch (dateRangeType) {
    case 'This Week': {
      const previousStartDate = startDate
        .clone()
        .startOf('week')
        .subtract(1, 'week')
      const previousEndDate = endDate.clone().subtract(1, 'week')
      return { previousStartDate, previousEndDate }
    }

    case 'MTD': {
      const previousStartDate = startDate
        .clone()
        .startOf('month')
        .subtract(1, 'month')
      const previousEndDate = endDate.clone().subtract(1, 'month')
      return { previousStartDate, previousEndDate }
    }

    case 'QTD': {
      const previousStartDate = startDate
        .clone()
        .startOf('quarter')
        .subtract(1, 'quarter')
      const previousEndDate = endDate.clone().subtract(1, 'quarter')
      return { previousStartDate, previousEndDate }
    }

    case 'YTD': {
      const previousStartDate = startDate
        .clone()
        .startOf('year')
        .subtract(1, 'year')
      const previousEndDate = endDate.clone().subtract(1, 'year')
      return { previousStartDate, previousEndDate }
    }

    default: {
      // the difference between days is smaller by one than the actual number of days
      const rangeInDays = endDate.diff(startDate, 'days') + 1
      const previousStartDate = startDate
        .clone()
        .subtract(rangeInDays, 'days')
        .startOf('day')
      const previousEndDate = startDate
        .clone()
        .subtract(1, 'days')
        .endOf('day')
      return { previousStartDate, previousEndDate }
    }
  }
}

const getPercentDiff = (current, old) => {
  return ((current - old) / old) * 100
}

export const trendsDataAvailable = (
  baseDate,
  startDate,
  endDate,
  comparisonType
) => {
  const trendDataStarts = baseDate.clone().subtract(2, 'years')
  const { previousStartDate } = computePreviousPeriodRange(
    startDate,
    endDate,
    comparisonType
  )
  return (
    startDate &&
    endDate &&
    previousStartDate.isSameOrAfter(trendDataStarts.subtract(1, 'day'), 'day')
  )
}

const scorecardAccessors = [
  'total_brands',
  'total_revenue',
  'monthly_unit_sales',
  'average_reviews',
  'total_asins',
  'average_price'
]

export const generateTrendTooltipData = (
  value,
  endDate,
  prevValue,
  previousEndDate,
  accessor,
  currencyCode,
  minDigits,
  maxDigits
) => {
  const currencyAccessors = ['total_revenue_trend', 'average_price_trend']
  const isCurrencyValue = currencyAccessors.includes(accessor)

  let currentValue = value
  let previousValue = prevValue

  if (isCurrencyValue) {
    currentValue = parseCurrency(currentValue, getCurrencyCode(currencyCode))
    previousValue = parseCurrency(previousValue, getCurrencyCode(currencyCode))
  } else if (accessor === 'share_trend') {
    currentValue =
      currentValue < 0.01 ? '< 0.01%' : `${currentValue.toFixed(2)}%`
    previousValue =
      previousValue < 0.01 ? '< 0.01%' : `${previousValue.toFixed(2)}%`
  } else {
    currentValue = humanizeLongNumberWithLocale(
      currentValue,
      undefined,
      minDigits,
      maxDigits
    )
    previousValue = humanizeLongNumberWithLocale(
      previousValue,
      undefined,
      minDigits,
      maxDigits
    )
  }

  return {
    currentValue,
    currentEndDate: moment(endDate).format('MMM D, YYYY'),
    previousValue,
    previousEndDate: moment(previousEndDate).format('MMM D, YYYY')
  }
}

export const calculatePreviousAggregateSegmentData = (
  segmentTrends,
  previousStartDate,
  previousEndDate
) => {
  return aggregateSegmentData(segmentTrends, previousStartDate, previousEndDate)
}

export const calculateTrendsData = (
  segmentTrends,
  segmentData,
  endDate,
  currencyCode,
  previousStartDate,
  previousEndDate,
  unitSalesDigits
) => {
  const previousData = calculatePreviousAggregateSegmentData(
    segmentTrends,
    previousStartDate,
    previousEndDate
  )

  const initialValue = {}

  return scorecardAccessors.reduce((finalObject, accessor) => {
    const new_key = `${accessor}_trend`
    const currentValue = segmentData[accessor]
    const previousValue = previousData[accessor]
    const diff = getPercentDiff(currentValue, previousValue)
    const digits =
      accessor === 'monthly_unit_sales' ? unitSalesDigits : undefined
    return {
      ...finalObject,
      [new_key]: {
        value: diff,
        tooltip: generateTrendTooltipData(
          currentValue,
          endDate,
          previousValue,
          previousEndDate,
          new_key,
          currencyCode,
          digits,
          digits
        )
      }
    }
  }, initialValue)
}

export const getBrandTrendData = selectedBrand => {
  return {
    total_revenue_trend: selectedBrand.revenueTrend,
    monthly_unit_sales_trend: selectedBrand.unitSalesTrend,
    share_trend: selectedBrand.shareTrend
  }
}

export const transformPreviousDatesToParams = (
  previousStartDate,
  previousEndDate
) => {
  if (previousStartDate && previousEndDate) {
    const trendDataStarts = moment()
      .subtract(1, 'day')
      .subtract(2, 'years')
    const dataAvailable = previousStartDate.isSameOrAfter(
      trendDataStarts,
      'day'
    )

    return dataAvailable
      ? {
          comparison_start_date: previousStartDate.valueOf(),
          comparison_end_date: previousEndDate.valueOf()
        }
      : {}
  }
  return {}
}

export const trackShareAndTrendsFiltersOnSegment = ({
  segment,
  startDate,
  endDate,
  selectedBrand
}) => {
  safeSegmentCall(() => {
    const filters = [
      {
        type: 'Selected Brand',
        value: selectedBrand ? selectedBrand?.name : null
      },
      {
        type: 'Start Date',
        value: startDate ? new Date(startDate).toISOString() : null
      },
      {
        type: 'End Date',
        value: endDate ? new Date(endDate).toISOString() : null
      }
    ]

    const countryAttr = segment?.filters?.country

    const country =
      typeof countryAttr === 'object'
        ? countryAttr?.valuesArray[0]
        : countryAttr

    sendSegmentTrackEvent(SHARE_AND_TRENDS_FILTERED, {
      keyword: segment?.name,
      marketplace: country ? MARKETPLACES[country]?.name : null,
      filters
    })
  })
}

export const unitSalesFormatter = (value, isUnitSalesRoundingEnabled) => {
  const digits = isUnitSalesRoundingEnabled ? 0 : 1
  const options = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  }
  return parseFloat(value).toLocaleString('en-US', options)
}

export const getExportColumns = (isSellersSheetCol, t) => {
  return isSellersSheetCol
    ? [
        {
          key: 'id',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.sellerId',
            'Seller ID'
          ),
          width: 20
        },
        {
          key: 'name',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.sellerName',
            'Seller Name'
          ),
          width: 40,
          style: {
            alignment: {
              wrapText: true
            }
          }
        },
        {
          key: 'revenue',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.revenue',
            '3P Revenue'
          ),
          width: 15
        },
        {
          key: 'revenueShare',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.revenueShare',
            '3P Revenue Share'
          ),
          width: 15
        },
        {
          key: 'unitSales',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.unitSales',
            '3P Units'
          ),
          width: 15
        },
        {
          key: 'unitSalesShare',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.unitSalesShare',
            '3P Units Share'
          ),
          width: 15
        },
        {
          key: 'buyBoxWinnerShare',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.buyBoxWinnerShare',
            'BBW %'
          ),
          width: 15
        }
      ]
    : [
        {
          key: 'asin',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.asin',
            'ASIN'
          ),
          width: 15
        },
        {
          key: 'variantInfo',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.variantInfo',
            'Variant Info'
          ),
          width: 25,
          style: {
            alignment: {
              wrapText: true
            }
          }
        },
        {
          key: 'variantGroup',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.variantGroup',
            'Variant Group'
          ),
          width: 15
        },
        {
          key: 'sellerId',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.sellerId',
            'Seller ID'
          ),
          width: 20
        },
        {
          key: 'sellerName',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.sellerName',
            'Seller Name'
          ),
          width: 40,
          style: {
            alignment: {
              wrapText: true
            }
          }
        },
        {
          key: 'productName',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.productName',
            'Product Name'
          ),
          width: 40,
          style: {
            alignment: {
              wrapText: true
            }
          }
        },
        {
          key: 'brand',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.brand',
            'Brand'
          ),
          width: 15
        },
        {
          key: 'revenue',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.revenue',
            '3P Revenue'
          ),
          width: 15
        },
        {
          key: 'revenueShare',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.revenueShare',
            '3P Revenue Share'
          ),
          width: 15
        },
        {
          key: 'unitSales',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.unitSales',
            '3P Units'
          ),
          width: 15
        },
        {
          key: 'unitSalesShare',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.unitSalesShare',
            '3P Units Share'
          ),
          width: 15
        },
        {
          key: 'buyBoxWinnerShare',
          header: t(
            'market_insights:ShareAndTrends.ExportExcel.Columns.buyBoxWinnerShare',
            'BBW %'
          ),
          width: 15
        }
      ]
}

export const formatExportedSellersData = (sellersData, code) => {
  return sellersData.map(seller => {
    const {
      id,
      name,
      revenue,
      revenueShare,
      unitSales,
      unitSalesShare,
      buyBoxWinnerShare
    } = seller
    return {
      id,
      name,
      revenue: revenue
        ? parseCurrency(revenue, code, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })
        : null,
      revenueShare: revenueShare ? `${revenueShare.toFixed(1)}%` : null,
      unitSales: unitSales ? Math.round(unitSales).toLocaleString() : null,
      unitSalesShare: unitSalesShare ? `${unitSalesShare.toFixed(1)}%` : null,
      buyBoxWinnerShare: buyBoxWinnerShare
        ? `${buyBoxWinnerShare.toFixed(2)}%`
        : null
    }
  })
}

const productDataFormatter = (
  product,
  sellerId,
  sellerName,
  code,
  parentAsin
) => {
  return {
    asin: product.asin || null,
    variantInfo: parentAsin ? `Variant of ${parentAsin}` : 'Parent',
    variantGroup: parentAsin || product.asin,
    sellerId: sellerId || null,
    sellerName: sellerName || null,
    productName: product.name || null,
    brand: product.brand || null,
    revenue: product.revenue
      ? parseCurrency(product.revenue, code, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      : null,
    revenueShare: product.revenueShare
      ? `${product.revenueShare.toFixed(1)}%`
      : null,
    unitSales: product.unitSales
      ? Math.round(product.unitSales).toLocaleString()
      : null,
    unitSalesShare: product.unitSalesShare
      ? `${product.unitSalesShare.toFixed(1)}%`
      : null,
    buyBoxWinnerShare: product.buyBoxWinnerShare
      ? `${product.buyBoxWinnerShare.toFixed(2)}%`
      : null
  }
}

export const formatExportedProductData = (sellersData, code) => {
  return sellersData.reduce((all, seller) => {
    const products =
      seller.subRows?.length >= 1
        ? seller.subRows.reduce((allProduct, subRow) => {
            const parent = productDataFormatter(
              subRow,
              seller.id,
              seller.name,
              code
            )
            let children = []
            if (subRow.subRows?.length >= 1) {
              children = subRow.subRows.map(child => {
                return productDataFormatter(
                  child,
                  seller.id,
                  seller.name,
                  code,
                  parent.asin
                )
              })
            }
            return [...allProduct, parent, ...children]
          }, [])
        : []
    return [...all, ...products]
  }, [])
}

export const median = array => {
  if (array.length === 0) return

  const mid = Math.floor(array.length / 2)
  array = [...array].sort((a, b) => a - b)

  if (array.length % 2 !== 0) {
    return array[mid]
  }
  return (array[mid - 1] + array[mid]) / 2
}

export const mean = array => {
  if (array.length === 0) return

  const sum = array.reduce((a, b) => a + b, 0)

  return sum / array.length
}

export const percentageToValue = (percent, min = 0, max = 100) => {
  return (percent * (max - min)) / 100 + min
}

export const valueToPercentage = (value, min = 0, max = 100) => {
  return ((value - min) * 100) / (max - min)
}

export const calculatePercentageDifference = (
  originalValue,
  newValue,
  round = true
) => {
  const isNewValueBigger = newValue > originalValue
  const isOriginalValueNegative = originalValue < 0

  if (originalValue === 0) {
    return newValue
  }

  const diff = isNewValueBigger
    ? newValue - originalValue
    : originalValue - newValue

  const originalPercentage = (diff / originalValue) * 100

  const percentage = round ? Math.round(originalPercentage) : originalPercentage

  let formattedPercentage = isNewValueBigger ? percentage : percentage * -1

  formattedPercentage = isOriginalValueNegative
    ? formattedPercentage * -1
    : formattedPercentage

  return formattedPercentage
}

/* eslint-disable no-restricted-syntax */
import { ASIN_LIMIT } from 'src/js/constants/admin'
import { MARKETPLACES } from 'constants/countries'
import { sendSegmentTrackEvent, safeSegmentCall } from 'services/segment'
import i18next from 'i18next'

export const hasSegmentBuilderAccess = featureAccess => {
  return featureAccess?.cobalt?.segmentBuilder
}

export const asinLimitsNotificationsEnabled = featureAccess => {
  return featureAccess?.cobalt?.asinLimitsNotifications
}

export const membershipAsinLimit = featureLimits => {
  return featureLimits?.cobalt?.membershipAsinLimit
}

export const membershipUsagePercentual = (limit, currentUsage) => {
  if (limit === -1 || limit === 0) return 0
  return parseFloat((((currentUsage || 0) / limit) * 100).toFixed(1))
}

export const userAsinLimit = membershipInfo => {
  const { access_right, code } = membershipInfo

  const asinLimit =
    code === 'COBALT' && access_right?.permissions?.cobalt
      ? access_right.permissions.cobalt.asin_limit
      : ASIN_LIMIT

  return asinLimit
}

export const segmentCountry = segment => {
  if (!segment?.filters?.country) {
    return null
  }
  const {
    v2,
    filters: { country }
  } = segment
  return v2 ? country : country.valuesArray[0]
}

export const sendSegmentTrackingEvent = (event, segmentData) => {
  safeSegmentCall(() => {
    // Segment V1 countries is an array, for V2 it is just a string
    const { country } = segmentData.filters || {}
    const code = typeof country === 'string' ? country : country?.valuesArray[0]
    sendSegmentTrackEvent(event, {
      segmentName: segmentData.name,
      marketplace: MARKETPLACES[code]?.name,
      brands: segmentData.total_brands,
      asins: segmentData.total_asins,
      dateCreated: segmentData.created_at,
      lastUpdated: segmentData.updated_at
    })
  })
}

export const formatRefreshType = refreshType => {
  switch (refreshType) {
    case 'segment_full_build':
      return i18next.t(
        'market_insights:SegmentManagerTable.segmentFullBuild',
        'Segment full build'
      )
    case 'segment_refreshed':
      return i18next.t(
        'market_insights:SegmentManagerTable.segmentRefresh',
        'Recurring refresh'
      )
    default:
      return undefined
  }
}

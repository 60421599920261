import isEmpty from 'lodash/isEmpty'
import { parseQueryString } from '../url'
import { getGlobalData } from '../storage'

export const findbyQueryString = () => {
  const { search, hash } = window.location

  // window.location returns "" in most cases because of the # in the url
  const { lang: searchLang } = parseQueryString(search)
  const { lang: hashLang } = parseQueryString(hash)

  return searchLang || hashLang
}

export const loadFromLocal = () => {
  const globalData = getGlobalData()

  if (isEmpty(globalData)) {
    return null
  }

  if (!globalData.user) {
    return null
  }

  return globalData.user.selected_language
}

export const findbyNavigator = () => {
  // Possible values https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/languages)
  if (!navigator) return null

  // Chrome + Firefox
  if (navigator.languages) return navigator.languages[0]

  if (navigator.language) return navigator.language

  // Windows
  if (navigator.userLanguage) return navigator.userLanguage

  return null
}

export const MEDIA_QUERIES = {
  tinyPhone: { type: 'max', size: 360 },
  phone: { type: 'max', size: 455 },
  notPhone: { type: 'min', size: 456 },
  max600: { type: 'max', size: 600 },
  max700: { type: 'max', size: 700 },
  min850: { type: 'min', size: 850 },
  max850: { type: 'max', size: 850 },
  tablet: { type: 'max', size: 940 },
  desktop: { type: 'min', size: 940 },
  max1160: { type: 'max', size: 1160 },
  smallDesktop: { type: 'min', size: 1200 },
  max1240: { type: 'max', size: 1240 },
  regularDesktop: { type: 'max', size: 1366 },
  largeDesktop: { type: 'min', size: 1440 },
  maxLargeDesktop: { type: 'max', size: 1440 },
  extraLargeDesktop: { type: 'min', size: 1920 }
}

export const media = Object.keys(MEDIA_QUERIES).reduce((acc, key) => {
  const { type, size } = MEDIA_QUERIES[key]
  acc[key] = style => `
    @media (${type}-width: ${size}px) {
      ${style};
    }
  `
  return acc
}, {})

import { findbyQueryString, loadFromLocal, findbyNavigator } from './lookups'

const ENGLISH = 'en-US'
const CHINESE = 'zh-Hant'
const NA_DOMAIN = 'members.junglescout.com'
const NA_DOMAIN_STAGING = 'js-staging.dev-junglescout.com'
const NA_DOMAIN_REVIEW = 'js-review-client.dev-junglescout.com'
const NA_DOMAIN_DEV = 'localhost:8080'
const CHINESE_DOMAIN = 'members.junglescout.cn'

export class LanguageDetector {
  detect(queryLangOverride) {
    try {
      // Global Data Check
      const globalVal = loadFromLocal()

      if (globalVal) return globalVal

      // Query String Check
      const queryVal = queryLangOverride || findbyQueryString()

      if (queryVal) {
        const usableQueryVal = this._normalizeQueryLang(queryVal)

        if (usableQueryVal) return usableQueryVal
      }

      // Browser Language Check
      const browserVal = findbyNavigator()

      if (browserVal) {
        const usableBrowserVal = this._normalizeBrowserLang(browserVal)

        if (usableBrowserVal) return usableBrowserVal
      }
    } catch (_ex) {} // eslint-disable-line no-empty

    // If all checks failed or err default lang based on domain
    return this._defaultByDomain()
  }

  showCaptcha() {
    try {
      // Google Recaptcha doesn't work in China
      if (['zh', 'zh-Hant'].includes(findbyQueryString())) return false

      return [
        NA_DOMAIN,
        NA_DOMAIN_STAGING,
        NA_DOMAIN_REVIEW,
        NA_DOMAIN_DEV
      ].includes(window.location.host)
    } catch (_ex) {} // eslint-disable-line no-empty
  }

  _defaultByDomain() {
    const domain = window.location.host

    switch (domain) {
      case NA_DOMAIN:
        return ENGLISH
      case CHINESE_DOMAIN:
        return CHINESE

      default:
        return ENGLISH
    }
  }

  _normalizeBrowserLang(browserVal) {
    // match examples: en || en-US || EN-GB || en-hant
    if (browserVal.match(/^en(?:-[a-z]{2,4})?$/i)) return ENGLISH

    if (browserVal.match(/^zh(?:-[a-z]{2,4})?$/i)) return CHINESE

    return null
  }

  _normalizeQueryLang(queryVal) {
    switch (queryVal.toUpperCase()) {
      case 'EN':
        return ENGLISH
      case 'ZH':
        return CHINESE
      default:
        return queryVal
    }
  }
}
